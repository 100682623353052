.navbar{
    padding: 0;
    flex-wrap: nowrap;
}
.nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    &.collapse:not(.show){
        display: flex;
    }
    &__top{
        // height: 120px;
        ul {
			// height: 120px;
            flex-direction: row;
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                margin: 0 0.5rem; 
                // height: 120px;
                a {
                    // outline: 1px solid lime;
                    // height: 120px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    transition: all 0.5s ease;                    
                    // border-bottom: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    // border-radius: 15px;
                    text-decoration: none;
                    padding: 2rem 1.5rem!important; 
                    line-height: normal;
                    white-space: nowrap;
                    color: $white;
                    font-family: 'Space Grotesk', sans-serif; // 700
                    font-weight: 700 !important;
                    letter-spacing: 1px;
                    font-size: 20px; 
                    &:hover, 
                    &.active{
                        color: $white !important;
                        text-decoration: none;
                        // border-bottom: 5px solid $color4;
                        border-bottom: 5px solid $white;
                        // background-color: lighten($color2, 20%);
                    }
                }
                &:last-child{
                    a{
                        padding-right: 0 !important;
                    }
                }
                &.contact{
                    padding-top: 1rem;
                    a{
                        padding: 0 !important;
                        span{
                            background-color: $dark;
                            border-bottom: none;
                            // border-radius: 15px;
                            border-radius: 2rem;
                            color: $white;
                            padding: 1.0rem 1.5rem;
                            font-weight: 700;
                        }
                        &:hover,
                        &.active{
                            border-bottom-color: transparent;
                            span{
                                background-color: $color4;
                                color: $color1;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .nav{
        &__top{
            ul{
                li{ 
                    a{
                        padding: 2rem .35rem!important; 
                        font-size: 18px; 
                    }
                    &.contact{
                        a{
                            padding: 0.5rem 0.25rem !important;
                            span{
                                color: $white;
                                padding: 0.5rem 1.0rem;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }  
    }
} 
@include media-breakpoint-down(lg) { 
    .navbar{
        flex-wrap: wrap;
        justify-content: space-between;
        .navbar-toggler-icon{
            position: relative;
            background-image: none;
            display: inline-block;
            height: 4px;
            width: 25px;
            background: $white;
            transition: background 0.3s 0.3s;
            &:before{
                position: absolute;
                display: inline-block;
                height: 4px;
                width: 25px;
                background: $white;
                top: -8px;
                content: '';
                right: 0;
                transform: translateZ(0);
                backface-visibility: hidden;
                transform-origin: 0% 50%;
                transition: all 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
            }
            &:after{
                position: absolute;
                display: inline-block;
                height: 4px;
                width: 25px;
                background: $white;
                top: 8px;
                content: '';
                right: 0;
                transform: translateZ(0);
                backface-visibility: hidden;
                transform-origin: 0% 50%;
                transition: all 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
            }
        }
        .navbar-toggler{
            background-color: transparent;
            padding: 0.5rem;
            border: 0;
            &:focus{
                outline: none !important;
                box-shadow: none;
            }
            &[aria-expanded="true"],
            &.active{
                .navbar-toggler-icon{
                    background: transparent;
                    &:before{
                        transform: translateX(4px) translateY(-4px) rotate(45deg);
                    }
                    &:after{
                        transform: translateX(4px) translateY(-2px) rotate(-45deg);
                    }
                }
            }
        }
        .nav{
            &.collapse:not(.show){
                display: none;
            }
            &__top{
                height: auto;
                width: 100%;
                ul{
                    flex-wrap: wrap;
                    align-items: center;
                    height: auto;
                    li{
                        margin: 0 auto;
                        height: auto;
                        a{
                            height: auto;
                            padding: 1.5rem 1.5rem!important;
                        }
                    }
                }
            }
        }
        &:focus{
            outline: none !important;
        }
    }
}

@include media-breakpoint-only(xs) {
    .navbar{
        .navbar-brand{
            order: 1;
        }
        .navbar-toggler{
            order: 2;
        }
        .nav {
            order: 3;
            display: flex;
            align-items: center;
            &__top{
                margin-top: 0 !important;
                ul{
                    flex-wrap: wrap;
                    flex-direction: column;
                    margin: 3rem 0;
                    height: auto;
                    li{
                        margin: 0 auto;
                        height: auto;
                        a{
                            display: inline-block;
                            height: auto;
                            padding: 1.2rem 0.5rem!important;
                            letter-spacing: 0;
                        }
                        &.contact{
                            // flex-basis: 100%;
                            margin: 1rem auto 0.5rem;
                            a{
                                span{
                                    padding: 0.6rem 1.5rem 0.7rem;
                                }
                            }
                        }
                    }
                }
            }
            &__top{
                order: 3;
                margin-top: 1rem;
                padding-bottom: 1rem;
                // border-bottom: 1px solid $gray-400;
            }
            &__lang{
                order: 1;
                border-left: 0 solid #000;
                padding-left: 0;
                margin-left: 0;
            }
        }
    }
}