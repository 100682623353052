// BUTTONS
.btn {
  &.btn-lg {
    font-size: inherit;
    padding: 0.8rem 1.5rem;
    border-radius: 2rem;
  }
  &:hover{
    text-decoration: none !important;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &.btn-theme {
    border-color: $color1;
    background-color: $color1;
    color: white;

    &:hover {
      color: white;
      border-color: lighten($color1, 6%) !important;
      background-color: lighten($color1, 6%) !important;
    }
  }

  &.btn-light {
    background-color: #eee;
    border-color: #eee;

    &:focus,
    &:hover {
      background-color: #e2e6ea;
    }
  }

  &.btn-outline-light {
    background-color: #fff;
    border-color: $gray-400;
    color: $dark;

    &:hover {
      border-color: $gray-800;
    }
  }

  &.btn-angle-right {
    display: inline-flex;
    align-items: center;

    .icon {
      margin-left: 0.15rem;
      height: 12px;
      width: 12px;
    }

    &.angle-dark {
      .icon {
        fill: $color1;
      }

      &:hover {
        .icon {
          fill: red;
        }
      }
    }

    &.angle-light {
      .icon {
        fill: $white;
      }

      &:hover {
        .icon {
          fill: red;
        }
      }
    }
  }
}

@include media-breakpoint-only(xs) {
  .btn-angle-right {
    align-items: initial;

    &:after {
      margin-top: 1px;
    }
  }
}

// CHECKBOX BUTTONS FOR ARTICLE DETAIL
.checkbox-btn {
  position: relative;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

// BUTTON CLOSE FOR MODALS
button.close {
  font-size: 1.75rem;

  &:focus,
  &:hover {
    outline: none;
  }
}