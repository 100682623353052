html {
	scroll-behavior: smooth; // back to top and other anchors
}
// body{
// 	background-color: #f8f9fa!important;
// }
// body, header, footer{
// 	background-color: $blue !important;
// }

$color1:#2b2b2b;
$color2: #675834; // olijfgroen
$color3:#B3D7E7; // blauw
$color4:#FFEB6F; // geel
$color5:#2f3742; // donkerblauw
$text-lt:#949494; // donkerblauw

$white: #ffffff;
$light:#F7F7FB; // licht grijs
$dark: #333333; // dark grey

.color1{ color:$color1!important;}
.color2{ color:$color2!important;}
.color3{ color:$color3!important;}
.color4{ color:$color4!important;}
.color5{ color:$color5!important;}
.white{ color:white!important;}

.bgcolor1{ background-image: none!important; background:$color1!important; color:white; h1, h2, h3, a{color:white;}}
.bgcolor2{ background-image: none!important; background:$color2!important; color:white; h1, h2, h3, a{color:white;};}
.bgcolor3{ background-image: none!important; background:$color3!important; }
.bgcolor4{ background-image: none!important; background:$color4!important; }
.bgcolor5{ background-image: none!important; background:$color5!important; }
.bgwhite{ background-image: none!important; background:white!important; }
.bglight{ background-image: none!important; background:$light!important; }

// ACCENT COLORS
$orange: #F6BF6A;
$pink: #FA9FBF;
$green: #B1D747;
$blue: #8ECADC;
$red: #E65448;
$yellow: #F2DF99;

// $greyGradient:linear-gradient(218deg,#f2f2f2 10.66%,#ffffff 49.05%)!important;
// $whiteGradient:linear-gradient(90deg,rgba(255,0,0,0) 25%,	rgba(255,0,0,0.5) 75%,	rgba(255,0,0,1) 100%)!important;
// $blackGradient: linear-gradient(0deg,rgba(#2f3742,0),	rgba(#2f3742,1) 50%,	rgba(#2f3742,1)) !important;
$blackGradient: linear-gradient(0deg,rgba(#2c476c,0),	rgba(#2c476c,0.5) 50%,	rgba(#2c476c,0.9)) !important;

img{
	max-width: 100%;
	height: auto;
	image-rendering: -webkit-optimize-contrast;
}

// VARIA

.b-radius-5{
	border-radius: 5px;
	img{
		border-radius: 5px;
	}
}
// @include media-breakpoint-up(md) {}
// @include media-breakpoint-up(lg) {}