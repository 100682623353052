html, body{
	font-family: 'Arsenal', sans-serif!important; // 400;700
	color: $color1!important;
    font-weight: 400;
	font-size: 16px;
	line-height: normal;
    text-rendering: optimizeLegibility;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5{
    font-family: 'Space Grotesk', sans-serif; // 700
	font-weight: 700 !important;
    margin-bottom: 2rem;
}
h2, .h2{
    font-size: 3rem;
}
h3, .h3{
    font-size: 1.8rem;
}

.titlerow{
    .row{
        > div{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        h2{
            text-transform: uppercase;
            font-size: 4rem;
            color: $color3;
            padding: 0;
            background-color: transparent;
            color: $color5;
            border: none;
            letter-spacing: 1.5px;
        }
    }
}
.lead{
    font-size: 1.4rem;
    line-height: 150%;
    font-weight: 200;
    p{
        font-size: 1.4rem;
    }
    strong{
        font-weight: 600;
    }
}
a{
    &:hover{
        text-decoration: underline;
    }
    &.text-muted{
        color:$gray-500;
        font-size: 0.95rem; 
    }
}
.small, small{
    line-height: 110%;
    display: inline-block;
}
// CUSTOM UL WITH CHECK MARKS
.list-style-check {
    padding: 0;
    li {
        display: flex;
        margin-bottom: 0.25rem;
        &:before {
            // content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAAi2ky3AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD9SURBVHgBpZLJjcJAEEVfW8ydDAYysAczZxwJzgQTCSISCMASZOB2BHBnaaq8gEEGhPlSS73p1fYNXyjD7xt+Nrr3+EICWYEbwLk7KGe0EIgvyzpOUY8OyglnDmKB7BUyZGv5VAqxhE6X9Mini3L+pzfIOG6+Gcs4043jEEiK+2cQjW7obcq/zIekSfPd045r5+XT6gXk+t4GKUDaLO287P1yEm0QHTN9h1u2QYrSGhE1bTEYyS/pvLqvDKdeYTsgDXiiwkc6PoeJqtQTnUwZpTaceuUY8ULmvoy/2ODV5a1lTWrDvfOKebzICBO5nJWnwnBBJ8PVMMto94nhLrcxbKkny9GCAAAAAElFTkSuQmCC');
            content: url('../images/icons/check.svg');
            width: 16px;
            height: 16px;
            flex: none;
            vertical-align: middle;
            margin-right: 0.35rem;
            margin-top: 0.1rem;
            line-height: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }

}

@include media-breakpoint-down(lg) {
    h1, .h1{
        margin-bottom: 1rem;
        font-size: 2.5rem;
        line-height: 100%;
    }
    h2, .h2{
        margin-top: 1rem;
        font-size: 1.9em !important;
    }
    h3, .h3{
        font-size: 1.6em !important;
    }
    .lead{
        font-size: 1.2rem;
        p{
            font-size: 1.2rem;
        }
    }
}