header.header {
	position: fixed;
	top: 0;
	z-index: 9;
	width: 100%;
	margin: 0 auto;
	padding-bottom: 100px;
	&:after{
		content: '';
		position: absolute;
		transition: margin 0.2s ease; 
		background-color: $color2;
		box-shadow: 0 10px 10px rgba($color1,0.1);
		height: 110px;
		left: -140px;
		right: -140px;
		bottom: 20px;
		border-radius: 0 0 100% 100%;
	}
	.curve{
		background-color: $color2;
		// height: 120px;
		position: relative;
		z-index: 9999;
		display: flex;
		align-items: center;
		.container{
			max-width: 90vw;
			transition: margin 0.1s ease;
			margin-top: 1.0rem;
		}
	}

	&:not(.shrink) .nav__top .navbar-nav li:first-child a{
		// border-bottom: 5px solid $white;
		border-bottom: 5px solid $white;
		color: $white;
	}

	.navbar-brand {
		// outline: 1px solid lime;
		// padding: 2rem 0 1rem;
		transition: all .2s ease;
		img, svg {
			transition: all .2s ease;
			// width: 11rem;
			width: 360px;
		}
	}

	&.shrink {
		padding-bottom: 0;	
		&:after{
			margin-bottom: 100px;
		}
		.curve{
			box-shadow: 0 10px 10px rgba($color1,0.4);
			.container{
				margin-top: 0rem;
			}
		}
		.navbar-brand {
			padding: 0rem 0; 
			img {
				width: 300px;
			}
		}
	}
}

@include media-breakpoint-down(xl) {
	header.header{
		.curve{
			.container{
				max-width: 96vw;
			}
		}
		.navbar-brand{
			img {
				width: 220px;
			}
		}
		&.shrink {
			.navbar-brand{
				img {
					width: 220px;
				}
			}
		}
	}
}
@include media-breakpoint-down(lg) {
	header.header {
		padding-bottom: 50px;
		.curve {
			height: 107px;
			height: auto;
		}
		.navbar-brand {
			padding: 1rem 0;
			img, svg {
				width: 250px;
			}
		}
		&.shrink {
			.curve {
				height: auto;
			}
			.navbar-brand {
				padding: 1rem 0;
				img {
					width: 250px;
				}
			}
		}
	}
}
@include media-breakpoint-down(md) {
	header.header {
		padding-bottom: 50px;
		.curve {
			height: 94px;
			height: auto;
		}
		&:after{
			height: 60px;
			left: -140px;
			right: -140px;
			bottom: 20px;
		}
		&.shrink {
			.curve {
				height: auto;
			}
			.navbar-brand {
				padding: 1rem 0;
				img {
					// width: 6rem;
					width: 300px;
				}
			}
		}
	}
}

@include media-breakpoint-only(xs) {
	header.header {
		padding-bottom: 50px;
		.curve {
			height: 94px;
			height: auto;
		}
		&:after{
			height: 60px;
			left: -30px;
			right: -30px;
			bottom: 20px;
		}
		.navbar-brand {
			padding: 1rem 0;
			img, svg {
				// width: 7rem;
				width: 240px;
			}
		}
		&.shrink{
			.curve {
				height: auto;
			}
			.navbar-brand {
				padding: 1rem 0;
				img {
					// width: 5rem;
					width: 200px;
				}
			}
		}
	}
}

@media only print {
	header.header {
		position: inherit;
	}

	main,
	#main {
		margin-top: 0 !important;
	}

	.btn {
		display: none;
	}
}