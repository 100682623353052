// FORMS
.form{
    label{
        color: $color1;
        font-weight: 500;
        font-size: 0.9rem;
    }
}
.form-group{
    margin-bottom: 1.5rem;
}
label{
    margin-bottom: 0.1rem;
}
input[type=text],
input[type=email],
input[type=password],
textarea,
select{
    // border: none;
    // border-radius: 0 !important;
    // border: none !important;
    background-color: $white !important;
    padding: .375rem .75rem; 
    // &:focus{
    //     outline: none; 
    //     box-shadow: none;
    //     background-color: #F4F5F4;  
    // }
}
.form-control:focus{
    background-color: #F4F5F4;  
}
.navbar{
    input[type=text],
    input[type=email],
    input[type=password],
    textarea,
    select{
        border: none;
        border-radius: 0 !important;
        border: 1px solid $gray-300 !important;
        background-color: $white !important;
        padding: .375rem .75rem; 
        &:focus{
            outline: none; box-shadow: none;
            background-color: $white;  
            box-shadow: none;
        }
    }
    .form-control:focus{
        background-color: $white;  
    }
}

.form-check-input:checked{
    background-color: $color2 !important;
    border-color: $color2 !important;
}
.form-check-input:focus{
    box-shadow:0 0 0 0.25rem rgba($color2,.25);
    border-color: $color2;
}

// CUSTOM RADIO BOXED - as used in 30.40-ecommerce-checkout-address
.custom-radio-boxed {
    position: relative;
    margin-right: 5px;
    background-color: $white;
    padding: 0.8rem;
    border-radius: 0.35rem;
    border: 1px solid #dee2e6; 
    &:hover:not(.active) {
        border-color: #6ea8fe;
    }
    .form-check{
        position: initial;
    }
    .border-oncheck {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: 1px solid transparent;
        border-radius: 0.35rem;
        z-index: 1;
        mix-blend-mode: darken;
    }
    input:checked~.border-oncheck {
        border-color: #0d6efd;
        background-color: rgba(231,241,255,.5);
    }
}


// TOGGLE PASSWORD VISIBILTY
.togglePassword{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99999;
    cursor: pointer;
    width: 18px;
    height: 18px;
    &.hidecharacters{
        opacity: 0.5;
        content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktZXllLXNsYXNoIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik0xMy4zNTkgMTEuMjM4QzE1LjA2IDkuNzIgMTYgOCAxNiA4cy0zLTUuNS04LTUuNWE3LjAyOCA3LjAyOCAwIDAgMC0yLjc5LjU4OGwuNzcuNzcxQTUuOTQ0IDUuOTQ0IDAgMCAxIDggMy41YzIuMTIgMCAzLjg3OSAxLjE2OCA1LjE2OCAyLjQ1N0ExMy4xMzQgMTMuMTM0IDAgMCAxIDE0LjgyOCA4Yy0uMDU4LjA4Ny0uMTIyLjE4My0uMTk1LjI4OC0uMzM1LjQ4LS44MyAxLjEyLTEuNDY1IDEuNzU1LS4xNjUuMTY1LS4zMzcuMzI4LS41MTcuNDg2bC43MDguNzA5eiIvPgogIDxwYXRoIGQ9Ik0xMS4yOTcgOS4xNzZhMy41IDMuNSAwIDAgMC00LjQ3NC00LjQ3NGwuODIzLjgyM2EyLjUgMi41IDAgMCAxIDIuODI5IDIuODI5bC44MjIuODIyem0tMi45NDMgMS4yOTkuODIyLjgyMmEzLjUgMy41IDAgMCAxLTQuNDc0LTQuNDc0bC44MjMuODIzYTIuNSAyLjUgMCAwIDAgMi44MjkgMi44Mjl6Ii8+CiAgPHBhdGggZD0iTTMuMzUgNS40N2MtLjE4LjE2LS4zNTMuMzIyLS41MTguNDg3QTEzLjEzNCAxMy4xMzQgMCAwIDAgMS4xNzIgOGwuMTk1LjI4OGMuMzM1LjQ4LjgzIDEuMTIgMS40NjUgMS43NTVDNC4xMjEgMTEuMzMyIDUuODgxIDEyLjUgOCAxMi41Yy43MTYgMCAxLjM5LS4xMzMgMi4wMi0uMzZsLjc3Ljc3MkE3LjAyOSA3LjAyOSAwIDAgMSA4IDEzLjVDMyAxMy41IDAgOCAwIDhzLjkzOS0xLjcyMSAyLjY0MS0zLjIzOGwuNzA4LjcwOXptMTAuMjk2IDguODg0LTEyLTEyIC43MDgtLjcwOCAxMiAxMi0uNzA4LjcwOHoiLz4KPC9zdmc+');
    
    }
    &.showcharacters{
        content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktZXllIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik0xNiA4cy0zLTUuNS04LTUuNVMwIDggMCA4czMgNS41IDggNS41UzE2IDggMTYgOHpNMS4xNzMgOGExMy4xMzMgMTMuMTMzIDAgMCAxIDEuNjYtMi4wNDNDNC4xMiA0LjY2OCA1Ljg4IDMuNSA4IDMuNWMyLjEyIDAgMy44NzkgMS4xNjggNS4xNjggMi40NTdBMTMuMTMzIDEzLjEzMyAwIDAgMSAxNC44MjggOGMtLjA1OC4wODctLjEyMi4xODMtLjE5NS4yODgtLjMzNS40OC0uODMgMS4xMi0xLjQ2NSAxLjc1NUMxMS44NzkgMTEuMzMyIDEwLjExOSAxMi41IDggMTIuNWMtMi4xMiAwLTMuODc5LTEuMTY4LTUuMTY4LTIuNDU3QTEzLjEzNCAxMy4xMzQgMCAwIDEgMS4xNzIgOHoiLz4KICA8cGF0aCBkPSJNOCA1LjVhMi41IDIuNSAwIDEgMCAwIDUgMi41IDIuNSAwIDAgMCAwLTV6TTQuNSA4YTMuNSAzLjUgMCAxIDEgNyAwIDMuNSAzLjUgMCAwIDEtNyAweiIvPgo8L3N2Zz4=');
    }
}