body{
	background: $white;
	&.highlight{

	}
}
@include media-breakpoint-down(xl) {
	.container{
		max-width: 90%;
	}
}
section{
	// padding-top: 120px;
	// border: 3px solid red;
	&.hero{
		padding: 0;
	}
	&.intro{
		// padding-bottom: 120px;
		padding-bottom: 0;
	}
	&.diensten{
		padding-top: 0;
	}
}
// @include media-breakpoint-down(lg) {
// 	section{
// 		padding-top: 30px;
// 		padding-bottom: 30px;
// 	}
// }

.intro{
	// background-image: url('../images/bird.png') !important;
	// background-repeat: no-repeat;
	// background-position: 80% 60px;
	.row{
		div{
			&:last-child{
				padding-top: 7rem;
			}
		}
	}
	@include media-breakpoint-only(xs){
		.row{
			div{
				&:last-child{
					padding-top: 1rem;
				}
			}
		}
	}
}

.movie {
	margin: 0 auto;
	max-width: 1200px;
	padding: 0 1.5rem;
	position: relative;
	img{
		box-shadow: 0 10px 20px rgba($color1,0.15);
	}
	.btn-play {
		background-color: transparent;
		position: absolute;
		left: 1.5rem;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: calc(100% - 3rem);
		height: 100%;
		transition: all 0.1s ease;
		svg {
			position: relative;
			z-index: 1;
		}
	}
	@include media-breakpoint-down(xl){
		max-width: 90%;
		padding: 0 5%;
	}
	@include media-breakpoint-down(md) {
		.btn-play{
			svg{
				width: 90px;
			}
		}
	}
}

.diensten{
	&--icons{
		margin-top: 2rem;
		> div{
			.inner{	
				height: 100%;		
				background-color: lighten($color1,8%);
				padding: 0; 
			}
			p.h3{
				background-color: $color1;
				text-align: center;
				text-transform: uppercase;
				font-size: 1.4rem;
				letter-spacing: 3px;
				margin: 0;
				padding: 1.25rem 1rem;
				color: $color3;
			}
			p:not(.h3){
				margin: 0;
				padding: 1rem;
				font-size: 1.2rem;
				line-height: normal;
				color: $white;
				text-align: center;
			}
		}
	}
	@include media-breakpoint-down(xxl) {
		&--icons{
			> div{
				margin-bottom: 2rem;
				text-align: center;
				p.h3{
					background-color: $color1;
					text-align: center;
					font-size: 1.3rem !important;
					letter-spacing: 1px;
					padding: 1.0rem 0.2rem;
				}
			}
		}
	}
}

.team{
	position: relative;
	z-index: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	&--content{
		position: absolute;
		z-index: 3;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $white;
	}
	&--background{
		position: relative;
		// outline: 6px solid blue;
		img{
			width: 100%;
		}
	}
	.pill{
		display: inline-block;
		background: var(--bs-emphasis-color);
		border-radius: 25px;
		padding: 10px 20px;
	}
}
@include media-breakpoint-down(xl){
	.team, .team--content, .team--background{
		height: auto !important;
	}
}

body.fixedSection .team{ 
	position: relative;
	.team--content{
		position: absolute;
		position: fixed;
		bottom: 0;
		z-index: 2;
		z-index: 99999;
		width: 100%;
	}
	.team--background{
		position: fixed;
		bottom: 0;
		z-index: 1;
		width: 100%;
	}
}
@include media-breakpoint-down(xl){ 
	body.fixedSection .team{ 
		position: relative;
		.team--content{
			position: absolute;
			height: 100% !important;
		}
		.team--background{
			position: relative;
			width: 100%;
		}
	}
}

.footer{
	padding-top: 0;
	position: relative;
	z-index: 1;
	.container{
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-top: 0px;
		> div{
			padding: 4rem;
			color: $white;
		}
	}
	a{
		color: $white;
		text-decoration: none;
	}
	h4{
		margin-bottom: 0.85rem;
		color: $white;
		font-weight: 600 !important;
	}
	.legal{
		display: flex; 
		justify-content: flex-end;
		// justify-content: center;
		// background-color: $color2;
		padding: 0.5rem 0 !important;
		a{
		// 	padding: 0.5rem;
		// 	color: $white;
			opacity: 0.6;
		// 	font-size: 0.9rem;
		// 	text-decoration: none;
			&:hover{
				opacity: 1;
		// 		text-decoration: underline;
			}
		}
	}
}
@include media-breakpoint-down(xl){
	.footer{
		height: auto !important;
		background-color: $color2;
		.container{
			> div{
				padding: 2rem 0;
			}
		}
		h2{
			font-size: 2rem;
		}
		img{
			width: 250px;
		}
	}
}

.fancybox{
	&__backdrop{
		background: rgba(24, 24, 27, 0.95) !important;
	}
}
.has-html5video .fancybox__content{
	width: 1152px !important;	
	height: 648px !important;	
}

// WOW ANIMATION
.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.animated.hinge {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
}

@keyframes slideInLeft {
	from {
		transform: translate3d(-150%, 0, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

.slideInLeft {
	animation-name: slideInLeft;
}

@keyframes slideInRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(2000px);
		-ms-transform: translateX(2000px);
		transform: translateX(2000px);
	}

	100% {
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
}

.slideInRight {
	-webkit-animation-name: slideInRight;
	animation-name: slideInRight;
}

// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) {}`
// @include media-breakpoint-up(sm) {}
// @include media-breakpoint-up(md) {}
// @include media-breakpoint-up(lg) {}
// @include media-breakpoint-up(xl) {}
// @include media-breakpoint-up(xxl) {}

// No media query necessary for xs breakpoint as it's effectively `@media (max-width: 0) {}`
// @include media-breakpoint-down(sm) {}
// @include media-breakpoint-down(md) {}
// @include media-breakpoint-down(lg) {}
// @include media-breakpoint-down(xl) {}
// @include media-breakpoint-down(xxl) {}

// @include media-breakpoint-only(xs) {}
// @include media-breakpoint-only(sm) {}
// @include media-breakpoint-only(md) {}
// @include media-breakpoint-only(lg) {}
// @include media-breakpoint-only(xl) {}
// @include media-breakpoint-only(xxl) {}


/*
Bootstrap 5 - Breaking Media query mixins parameters have changed for a more logical approach.

media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) 
targets viewports smaller than lg).
Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of 
the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).
*/