.hero {
    position: relative;
    &--inner{
        position: relative;
    }
    &--image{
        img{
            width: 100%;
            height: auto;
            // aspect-ratio: 25/12;
        }
    }
    &--caption {
        // display: none !important;
        overflow: hidden;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        h1 {
            // opacity: 0.6;
            max-width: 50vw;
            background-color: rgba($color2, 0.75);
            // background-color: $color2;
            margin-bottom: 0;
            padding: 3rem;
            font-size: 2.0rem;
            line-height: normal;
            // font-weight: 300 !important;
            letter-spacing: 1px;
            line-height: normal;
            color: $white;
        }
        .btn{
            // padding: 0.56rem 1.5rem;
            // background-color: $color4;
            border-radius: 0;
            line-height: normal;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 200;
        }
    }
}

@include media-breakpoint-down(xl) {
    .hero--caption{
        h1{
            font-size: 2.0rem !important;
        }
    }
}

@include media-breakpoint-down(lg) {
    .hero{
        &--inner{
            display: flex;
            flex-direction: column;
        }
        &--caption{
            order: 2;
            position: relative;
            background-color: $color3;
            h1{
                max-width: none;
                width: 100%;
                background-color: $color3;
                padding:2.0rem 1.5rem;
                text-align: center;
                font-size: 1.8rem !important;
                color: $color1;
            }
            .btn{ display: none;}
        }
        &--image {
            order: 1;
        }
    }
}

@include media-breakpoint-only(xs) {
    // .hero{
    //     &--inner{
    //         display: flex;
    //         flex-direction: column;
    //     }
    //     &--caption{
    //         order: 2;
    //         position: relative;
    //         background-color: $color3;
    //         h1{
    //             max-width: none;
    //             background-color: $color3;
    //             padding:2.0rem 1rem;
    //             font-size: 1.8rem !important;
    //             color: $color1;
    //         }
    //         .btn{ display: none;}
    //     }
    //     &--image {
    //         order: 1;
    //     }
    // }
}